export const PATH_LOGIN = "/login";
export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_USERS = "/users";
export const PATH_ROLES = "/roles";

export const RESERVED_CLAIMS = [
  "acr",
  "auth_time",
  "exp",
  "jti",
  "amr",
  "azp",
  "firebase",
  "nbf",
  "at_hash",
  "cnf",
  "iat",
  "nonce",
  "aud",
  "c_hash",
  "iss",
  "sub",
];
