import React, { Fragment } from "react";
import { Formik, Field, FormikHelpers } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { InputAdornment, Grid, Button } from "@material-ui/core";
import { ProgressButton } from "./ProgressButton";
import SaveIcon from "@material-ui/icons/Save";
import * as Yup from "yup";
import CancelIcon from "@material-ui/icons/Cancel";
import RoleSelector from "./RoleSelector";

const buildValidationSchema = (withPassword: boolean) => {
  return Yup.object().shape({
    fullName: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phoneNumber: Yup.string().length(9),
    ...(withPassword && {
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string().test(
        "password-match",
        "Passwords do not match",
        function (value) {
          const { password } = this.parent;
          return password === value;
        }
      ),
    }),
  });
};

const useStyles = makeStyles((theme) => ({
  saveButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

export interface UserFormValues {
  fullName: string;
  email: string;
  phoneNumber?: string;
  password?: string;
  confirmPassword?: string;
  roleNames: string[];
}

interface Props {
  initialValues: UserFormValues;
  onSubmit: (
    values: UserFormValues,
    actions: FormikHelpers<UserFormValues>
  ) => any;
  children?: React.ReactNode;
  omitPassword?: boolean;
  roles: Role[];
  onClose: () => void;
}

export default function UserForm({
  initialValues,
  onSubmit,
  omitPassword = false,
  roles,
  onClose,
}: Props) {
  const classes = useStyles();
  const validationSchema = buildValidationSchema(!omitPassword);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
      }) => {
        const handleAddRole = (roleName: string) => {
          setFieldValue("roleNames", [...values.roleNames, roleName]);
        };
        const handleRemoveRole = (roleName: string) => {
          setFieldValue(
            "roleNames",
            values.roleNames.filter((name) => name !== roleName)
          );
        };
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Field
                  name="fullName"
                  as={TextField}
                  label="Full Name"
                  placeholder="Full Name"
                  margin="normal"
                  error={!!(touched.fullName && errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  required
                  fullWidth
                />
                <Field
                  as={TextField}
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  type="email"
                  margin="normal"
                  required
                  error={!!(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
                <Field
                  as={TextField}
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                  margin="normal"
                  error={!!(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+251</InputAdornment>
                    ),
                  }}
                />
                {!omitPassword && (
                  <Fragment>
                    <Field
                      as={TextField}
                      name="password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                      margin="normal"
                      required
                      error={!!(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      fullWidth
                    />
                    <Field
                      as={TextField}
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      placeholder="Confirm Password"
                      margin="normal"
                      required
                      error={
                        !!(touched.confirmPassword && errors.confirmPassword)
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      fullWidth
                    />
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <RoleSelector
                  roles={roles}
                  activeRoleNames={values.roleNames}
                  onActivateRole={handleAddRole}
                  onDeactivateRole={handleRemoveRole}
                />
              </Grid>
              <Grid item xs={12}>
                <ProgressButton
                  type="submit"
                  inProgress={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.saveButton}
                  startIcon={<SaveIcon />}
                  fullWidth>
                  Save
                </ProgressButton>
                <Button
                  color="primary"
                  onClick={onClose}
                  fullWidth
                  startIcon={<CancelIcon color="primary" />}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}
