import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
//@ts-ignore
import { useMutation } from "react-query";
import { TextField, makeStyles, Button, Snackbar } from "@material-ui/core";
import { ProgressButton } from "./ProgressButton";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Alert from "@material-ui/lab/Alert";
import firebase from "../firebase";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .test("password-match", "Passwords do not match", function (value) {
      const { password } = this.parent;
      return password === value;
    }),
});

const useStyles = makeStyles((theme) => ({
  saveButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

interface FormValues {
  password: string;
}

interface Props {
  user: firebase.UserRecord;
  onClose: () => void;
}

const changePassword = (userId: string) => async (values: FormValues) => {
  const token = (await firebase.auth().currentUser?.getIdToken()) as string;
  return fetch("/api/change-password", {
    method: "PUT",
    headers: {
      "X-Firebase-ID-Token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: userId,
      ...values,
    }),
  }).then((res) => res.json());
};

export default function ChangePasswordForm({ user, onClose }: Props) {
  const classes = useStyles();
  const [mutate, { status, data, reset }]: [
    (values: FormValues) => any,
    MutationResult<any>
  ] = useMutation(changePassword(user.uid));
  useEffect(() => {
    if (status === "success" && !data.error) {
      onClose();
    }
  }, [onClose, status, data]);
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ password: "", confirmPassword: "" }}
      onSubmit={mutate}>
      {({ handleSubmit, touched, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              as={TextField}
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              margin="normal"
              fullWidth
              error={!!(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              required
            />
            <Field
              as={TextField}
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              placeholder="Confirm Password"
              margin="normal"
              fullWidth
              error={!!(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              required
            />
            <ProgressButton
              type="submit"
              inProgress={status === "loading"}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<SaveIcon />}
              className={classes.saveButton}>
              Save
            </ProgressButton>
            <Button
              color="primary"
              onClick={onClose}
              fullWidth
              startIcon={<CancelIcon color="primary" />}>
              Cancel
            </Button>
            <Snackbar
              open={status === "error" || !!(data && data.error)}
              autoHideDuration={3000}
              onClose={reset}>
              <Alert elevation={6} variant="filled" severity="error">
                {(data && data.error) || "Problem occurred changing password"}
              </Alert>
            </Snackbar>
          </form>
        );
      }}
    </Formik>
  );
}
