import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as constants from "../constants";
import Login from "./Login";
import AppShell from "./AppShell";
import ForgotPassword from "./ForgotPassword";
import Users from "./Users";
import Roles from "./Roles";

export default function Content() {
  return (
    <Router>
      <Switch>
        <Route path={constants.PATH_LOGIN}>
          <Login />
        </Route>
        <Route path={constants.PATH_FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        <Route>
          <AppShell>
            <Switch>
              <Route exact path="/">
                <Users />
              </Route>
              <Route path={constants.PATH_ROLES}>
                <Roles />
              </Route>
            </Switch>
          </AppShell>
        </Route>
      </Switch>
    </Router>
  );
}
