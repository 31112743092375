import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  progress: {
    marginBottom: theme.spacing(1),
  },
}));

export default function LoadingApp() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" className={classes.progress} />
      <Typography variant="body1">Loading App...</Typography>
    </div>
  );
}
