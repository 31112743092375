import React, { Fragment, useState } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Snackbar,
  Button,
} from "@material-ui/core";
import CenterPage from "./CenterPage";
import { ProgressButton } from "./ProgressButton";
import { Formik, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import mailSentImg from "../illustrations/mail-sent.svg";
import IllustrationMessage from "./IllustrationMessage";
import { useHistory } from "react-router-dom";
import firebase from "../firebase";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  resetButton: {
    marginBottom: theme.spacing(1),
  },
  mailImage: {
    maxWidth: 150,
  },
}));

interface FormValues {
  email: string;
}

export default function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [resetStatus, setResetStatus] = useState<"idle" | "error" | "success">(
    "idle"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    try {
      await firebase.auth().sendPasswordResetEmail(values.email);
      setResetStatus("success");
    } catch (err) {
      console.error(err);
      if (err.code === "auth/user-not-found") {
        setErrorMessage("User with this email address not found");
      } else {
        setErrorMessage("Problem occurred resetting password");
      }
      setResetStatus("error");
    }
    actions.setSubmitting(false);
  };
  return (
    <CenterPage hideLogo={resetStatus === "success"}>
      <div className={classes.root}>
        {resetStatus === "success" && (
          <IllustrationMessage
            classes={{
              image: classes.mailImage,
            }}
            imgSrc={mailSentImg}
            message="Success. We've emailed you a link you can use to reset your password."
          />
        )}
        {resetStatus !== "success" && (
          <Fragment>
            <Typography variant="h6">Reset Password</Typography>
            <Typography variant="subtitle1">
              Enter your email to reset your password
            </Typography>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ handleSubmit, isSubmitting, touched, errors }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    className={classes.formContainer}>
                    <Field
                      as={TextField}
                      name="email"
                      fullWidth
                      margin="normal"
                      label="Email Address"
                      placeholder="Email Address"
                      className={classes.textField}
                      required
                      error={!!(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <ProgressButton
                      inProgress={isSubmitting}
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      className={classes.resetButton}>
                      Reset Password
                    </ProgressButton>
                  </form>
                );
              }}
            </Formik>
            <Snackbar
              open={resetStatus === "error"}
              autoHideDuration={3000}
              onClose={() => setResetStatus("idle")}>
              <Alert elevation={6} variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </Fragment>
        )}
      </div>
      <Button onClick={() => history.push("/login")} color="primary" fullWidth>
        Back to Login
      </Button>
    </CenterPage>
  );
}
