import React from "react";
import { Field } from "formik";
import { makeStyles, TextField, Button, Snackbar } from "@material-ui/core";
import { ProgressButton } from "./ProgressButton";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import * as Yup from "yup";
import { RESERVED_CLAIMS } from "../constants";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .notOneOf(RESERVED_CLAIMS, "Reserved name. Input a different name")
    .matches(/^\S*$/, "Cannot contain empty spaces."),
  description: Yup.string(),
});

const useStyles = makeStyles((theme) => ({
  saveButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

export interface RoleFormValues {
  name: string;
  description: string;
}

interface Props {
  initialValues: RoleFormValues;
  onSubmit: (values: RoleFormValues) => void;
  isSubmitting: boolean;
  errorSubmitting: boolean;
  errorMessage?: string;
  clearSubmittingError: () => void;
  onClose: () => void;
}

export default function RoleForm({
  initialValues,
  onSubmit,
  isSubmitting,
  errorSubmitting,
  clearSubmittingError,
  onClose,
  errorMessage,
}: Props) {
  const classes = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ handleSubmit, touched, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name="name"
              as={TextField}
              label="Name"
              placeholder="Name"
              margin="normal"
              fullWidth
              error={!!(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              required
            />
            <Field
              name="description"
              as={TextField}
              label="Description"
              placeholder="Description"
              margin="normal"
              fullWidth
              error={!!(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
            <ProgressButton
              type="submit"
              inProgress={isSubmitting}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<SaveIcon />}
              className={classes.saveButton}>
              Save
            </ProgressButton>
            <Button
              color="primary"
              onClick={onClose}
              fullWidth
              startIcon={<CancelIcon color="primary" />}>
              Cancel
            </Button>
            <Snackbar
              open={errorSubmitting}
              autoHideDuration={3000}
              onClose={clearSubmittingError}>
              <Alert elevation={6} variant="filled" severity="error">
                {errorMessage ?? "Problem occurred saving role"}
              </Alert>
            </Snackbar>
          </form>
        );
      }}
    </Formik>
  );
}
