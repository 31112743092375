import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Button,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
//@ts-ignore
import { useQuery } from "react-query";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CreateUserForm from "./CreateUserForm";
import EditUserForm from "./EditUserForm";
import DataTablePage, { DataTablePageHeader } from "./DataTablePage";
import QueryLoader from "./QueryLoader";
import LockIcon from "@material-ui/icons/Lock";
import ChangePasswordForm from "./ChangePasswordForm";
import firebase from "../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  topBar: {
    padding: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  contentWrapper: {
    minHeight: 50,
    textAlign: "center",
  },
  roleChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface UsersResponse {
  users: firebase.UserRecord[];
}

async function getUsers() {
  const token = (await firebase.auth().currentUser?.getIdToken()) as string;
  return fetch("/api/users", {
    method: "GET",
    headers: {
      "X-Firebase-ID-Token": token,
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
}

export default function Users() {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<firebase.UserRecord | null>(
    null
  );
  const [
    passChangeUser,
    setPassChangeUser,
  ] = useState<firebase.UserRecord | null>(null);
  const response: QueryResult<UsersResponse> = useQuery("users", getUsers);
  const handleDialogOpen = (
    user: firebase.UserRecord | undefined = undefined
  ) => {
    if (user) {
      setUserToEdit(user);
    }
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setUserToEdit(null);
    setDialogOpen(false);
  };
  const handleChangePassDialog = () => {
    setPassChangeUser(null);
  };
  return (
    <DataTablePage>
      <DataTablePageHeader>
        <Typography variant="h5">Users</Typography>
        <div className={classes.grow} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialogOpen()}
          startIcon={<PersonAddIcon />}>
          Add User
        </Button>
      </DataTablePageHeader>
      <QueryLoader response={response}>
        {response.status === "success" && response.data.users && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Email Verified</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response.data.users.map((user) => {
                  return (
                    <TableRow key={user.uid}>
                      <TableCell component="th" scope="row">
                        {user.displayName}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.emailVerified ? "Yes" : "No"}</TableCell>
                      <TableCell>
                        {user.customClaims &&
                          Object.keys(user.customClaims).map((claim) => {
                            if (user.customClaims?.[claim]) {
                              return (
                                <Chip
                                  className={classes.roleChip}
                                  key={claim}
                                  label={claim}
                                  variant="outlined"
                                  color="secondary"
                                />
                              );
                            }
                            return null;
                          })}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit User">
                          <IconButton
                            color="secondary"
                            onClick={() => handleDialogOpen(user)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Change Password">
                          <IconButton
                            color="secondary"
                            onClick={() => setPassChangeUser(user)}>
                            <LockIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </QueryLoader>
      <Dialog
        open={!!passChangeUser}
        onClose={handleChangePassDialog}
        fullWidth>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          {passChangeUser && (
            <ChangePasswordForm
              user={passChangeUser}
              onClose={handleChangePassDialog}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="md">
        <DialogTitle>User</DialogTitle>
        <DialogContent>
          {!userToEdit && <CreateUserForm onClose={handleDialogClose} />}
          {userToEdit && (
            <EditUserForm user={userToEdit} onClose={handleDialogClose} />
          )}
        </DialogContent>
      </Dialog>
    </DataTablePage>
  );
}
