import React, { useState } from "react";
import DataTablePage, { DataTablePageHeader } from "./DataTablePage";
import {
  Typography,
  makeStyles,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  TableBody,
  TableRow,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
//@ts-ignore
import { useQuery } from "react-query";
import CreateRoleForm from "./CreateRoleForm";
import QueryLoader from "./QueryLoader";
import EditRoleForm from "./EditRoleForm";
import firebase from "../firebase";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  defaultRoleIcon: {
    marginRight: theme.spacing(1),
  },
}));

interface RolesResponse {
  roles: Role[];
}

async function getRoles() {
  const token = (await firebase.auth().currentUser?.getIdToken()) as string;
  return fetch("/api/roles", {
    method: "GET",
    headers: {
      "X-Firebase-ID-Token": token,
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
}

export default function Roles() {
  const classes = useStyles();
  const response: QueryResult<RolesResponse> = useQuery("roles", getRoles);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState<Role | null>(null);
  const handleDialogOpen = (role: Role | undefined = undefined) => {
    if (role) {
      setRoleToEdit(role);
    }
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setRoleToEdit(null);
    setDialogOpen(false);
  };
  return (
    <Container maxWidth="lg">
      <DataTablePage>
        <DataTablePageHeader>
          <Typography variant="h5">Roles</Typography>
          <div className={classes.grow} />
          <Button
            variant="contained"
            color="primary"
            startIcon={<GroupAddIcon />}
            onClick={() => handleDialogOpen()}>
            Add Role
          </Button>
        </DataTablePageHeader>
        <QueryLoader response={response}>
          {response.status === "success" && response.data.roles && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {response.data.roles.map((role) => {
                    return (
                      <TableRow key={role.name}>
                        <TableCell component="th" scope="row">
                          {role.name}
                        </TableCell>
                        <TableCell>{role.description}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </QueryLoader>
      </DataTablePage>
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Role</DialogTitle>
        <DialogContent>
          {!roleToEdit && <CreateRoleForm onClose={handleDialogClose} />}
          {roleToEdit && (
            <EditRoleForm role={roleToEdit} onClose={handleDialogClose} />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}
