import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { TextField, makeStyles, Snackbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import { ProgressButton } from "./ProgressButton";
import firebase from "../firebase";
import { useCurrentUser } from "../context/UserContext";
import CenterPage from "./CenterPage";

const useStyles = makeStyles((theme) => ({
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default function Login() {
  const [, currentUser] = useCurrentUser();
  const classes = useStyles();
  const history = useHistory();
  const [loginError, setLoginError] = useState("");
  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      setLoginError(err.message || "Problem occurred during signin.");
    }
  };
  if (currentUser) {
    return <Redirect to="/" />;
  }
  return (
    <CenterPage>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={handleLogin}>
        {({ values, handleSubmit, handleChange, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                name="email"
                onChange={handleChange}
                value={values.email}
                margin="normal"
                label="Email"
                placeholder="Email"
                fullWidth
                required
              />
              <TextField
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
                margin="normal"
                label="Password"
                placeholder="Password"
                fullWidth
                required
              />
              <ProgressButton
                type="submit"
                variant="contained"
                color="primary"
                inProgress={isSubmitting}
                fullWidth
                className={classes.loginButton}>
                Login
              </ProgressButton>
              <Button
                fullWidth
                onClick={() => history.push("/forgot-password")}
                color="primary">
                Forgot Password
              </Button>
            </form>
          );
        }}
      </Formik>
      <Snackbar
        open={!!loginError}
        autoHideDuration={3000}
        onClose={() => setLoginError("")}>
        <MuiAlert elevation={6} variant="filled" severity="error">
          {loginError}
        </MuiAlert>
      </Snackbar>
    </CenterPage>
  );
}
