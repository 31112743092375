import React from "react";
import { makeStyles, Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  topBar: {
    padding: 0,
  },
}));

interface Props {
  children: React.ReactNode;
}

export default function DataTablePage({ children }: Props) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

interface HeaderProps {
  children: React.ReactNode;
}

export function DataTablePageHeader({ children }: HeaderProps) {
  const classes = useStyles();
  return <Toolbar className={classes.topBar}>{children}</Toolbar>;
}
