import firebase from "../firebase";
//@ts-ignore
import { useQuery } from "react-query";

/* interface ReducerState {
  status: "loading" | "error" | "done";
  tokenResult: null | firebase.auth.IdTokenResult;
}

type Action =
  | { type: "success"; tokenResult: firebase.auth.IdTokenResult }
  | { type: "error" };

function reducer(_state: ReducerState, action: Action): ReducerState {
  switch (action.type) {
    case "success":
      return { status: "done", tokenResult: action.tokenResult };
    case "error": {
      return { status: "error", tokenResult: null };
    }
    default:
      throw new Error("Unrecognized action type");
  }
}

export function useCurrentUserToken() {
  const [, currentUser] = useCurrentUser();
  const [{ status, tokenResult }, dispatch] = useReducer(reducer, {
    status: "loading",
    tokenResult: null,
  });
  useEffect(() => {
    if (currentUser) {
      firebase
        .auth()
        .currentUser?.getIdTokenResult()
        .then((idTokenResult) =>
          dispatch({ type: "success", tokenResult: idTokenResult })
        )
        .catch(() => dispatch({ type: "error" }));
    }
  }, [currentUser]);
  return [status, tokenResult];
} */

function getIdTokenResult() {
  return firebase.auth().currentUser?.getIdTokenResult();
}

export function useCurrentUserToken(): QueryResult<
  firebase.auth.IdTokenResult
> {
  return useQuery("idTokenResult", getIdTokenResult);
}
