import React, { useEffect } from "react";
//@ts-ignore
import { useMutation, queryCache } from "react-query";
import RoleForm, { RoleFormValues } from "./RoleForm";
import firebase from "../firebase";

const createRole = async (roleValues: RoleFormValues) => {
  const token = (await firebase.auth().currentUser?.getIdToken()) as string;
  return fetch("/api/roles", {
    method: "POST",
    headers: {
      "X-Firebase-ID-Token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(roleValues),
  }).then((res) => res.json());
};

interface CreateRoleResponse {
  role: Role;
}

interface Props {
  onClose: () => void;
}

export default function CreateRoleForm({ onClose }: Props) {
  const [mutate, { status, reset, data }]: [
    (roleValues: RoleFormValues) => any,
    MutationResult<CreateRoleResponse>
  ] = useMutation(createRole, {
    onSuccess: () => {
      queryCache.refetchQueries("roles");
    },
  });
  useEffect(() => {
    if (status === "success" && !data.error) {
      onClose();
    }
  }, [onClose, status, data]);
  return (
    <RoleForm
      initialValues={{ name: "", description: "" }}
      onSubmit={mutate}
      isSubmitting={status === "loading"}
      errorSubmitting={status === "error" || (!!data && !!data.error)}
      errorMessage={data?.error}
      clearSubmittingError={reset}
      onClose={onClose}
    />
  );
}
