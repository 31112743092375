import React from "react";
//@ts-ignore
import { useMutation, queryCache } from "react-query";
import { UserFormValues } from "./UserForm";
import UserFormDialogContent from "./UserFormDialogContent";
import firebase from "../firebase";

interface CreateUserResponse {
  user?: firebase.UserRecord;
  success?: string;
  error?: string;
}

interface Props {
  onClose: () => void;
}

const createUser = async (userValues: UserFormValues) => {
  const token = (await firebase.auth().currentUser?.getIdToken()) as string;
  return fetch("/api/users", {
    method: "POST",
    headers: {
      "X-Firebase-ID-Token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userValues),
  }).then((res) => res.json());
};

export default function CreateUserForm({ onClose }: Props) {
  const [mutate, { status, data, reset }]: [
    (userValues: UserFormValues) => any,
    MutationResult<CreateUserResponse>
  ] = useMutation(createUser, {
    onSuccess: () => {
      queryCache.refetchQueries("users");
    },
  });

  return (
    <UserFormDialogContent
      saveStatus={status === "success" && data.error ? "error" : status}
      onSave={mutate}
      onErrorClose={reset}
      onClose={onClose}
    />
  );
}
