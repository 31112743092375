import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { ServerDownMessage } from "./IllustrationMessage";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    minHeight: 50,
  },
  center: {
    textAlign: "center",
  },
}));

interface Props<T> {
  children: React.ReactNode;
  response: QueryResult<T>;
}

export default function QueryLoader<T>({ children, response }: Props<T>) {
  const classes = useStyles();
  const { status, data } = response;
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.center}>
        {status === "loading" && <CircularProgress color="secondary" />}
        {status === "error" && (
          <ServerDownMessage message="Problem occurred loading data" />
        )}
        {status === "success" && data.error && (
          <Alert severity="error">{data.error}</Alert>
        )}
      </div>
      {children}
    </div>
  );
}
