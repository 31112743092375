import React from "react";
import { Box } from "@material-ui/core";
import { useCurrentUser } from "../context/UserContext";
import LoadingApp from "./LoadingApp";
import Content from "./Content";

function App() {
  const [status] = useCurrentUser();
  return (
    <Box height="100vh" position="relative">
      {status === "loading" && <LoadingApp />}
      {status === "finished" && <Content />}
    </Box>
  );
}

export default App;
