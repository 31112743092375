import React from "react";
import { makeStyles } from "@material-ui/core";
import AfLogo from "../illustrations/af-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  centerContainer: {
    maxWidth: 520,
    width: "100%",
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  afLogo: {
    maxWidth: 100,
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  children: React.ReactNode;
  hideLogo?: boolean;
}

export default function CenterPage({ children, hideLogo = false }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.centerContainer}>
        {!hideLogo && (
          <img
            src={AfLogo}
            className={classes.afLogo}
            alt="Addis Fortune Logo"
          />
        )}
        {children}
      </div>
    </div>
  );
}
