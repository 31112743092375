import React from "react";
import {
  makeStyles,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import QueryLoader from "./QueryLoader";
import { useCurrentUserToken } from "../hooks/useCurrentUserToken";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(1)}px}`,
    },
  },
  header: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  selectorGrid: {
    display: "grid",
    gridGap: theme.spacing(1),
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
  },
}));

interface Props {
  roles: Role[];
  activeRoleNames: string[];
  onActivateRole: (name: string) => void;
  onDeactivateRole: (name: string) => void;
}

export default function RoleSelector({
  roles,
  activeRoleNames,
  onActivateRole,
  onDeactivateRole,
}: Props) {
  const classes = useStyles();
  const tokenResponse = useCurrentUserToken();
  const isRoleActive = (role: Role): boolean => {
    return !!activeRoleNames.find((name) => name === role.name);
  };
  const handleRoleChange = (role: Role) => (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (ev.target.checked) {
      onActivateRole(role.name);
    } else {
      onDeactivateRole(role.name);
    }
  };
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">Roles</Typography>
      <QueryLoader response={tokenResponse}>
        {tokenResponse.status === "success" && (
          <div className={classes.selectorGrid}>
            {roles.map((role) => (
              <FormControlLabel
                key={role.name}
                control={
                  <Switch
                    checked={isRoleActive(role)}
                    onChange={handleRoleChange(role)}
                    color="secondary"
                    disabled={
                      role.name === "super_admin" &&
                      !tokenResponse.data?.claims?.super_admin
                    }
                  />
                }
                label={role.name}
              />
            ))}
          </div>
        )}
      </QueryLoader>
    </div>
  );
}
