import React, { useEffect } from "react";
//@ts-ignore
import { useMutation, queryCache } from "react-query";
import firebase from "../firebase";
import RoleForm, { RoleFormValues } from "./RoleForm";

const editRole = (name: string) => async (roleValues: RoleFormValues) => {
  const token = (await firebase.auth().currentUser?.getIdToken()) as string;
  return fetch("/api/roles", {
    method: "PUT",
    headers: {
      "X-Firebase-ID-Token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...roleValues,
      name,
    }),
  }).then((res) => res.json());
};

interface EditRoleResponse {
  role: Role;
}

interface Props {
  role: Role;
  onClose: () => void;
}

export default function EditRoleForm({ role, onClose }: Props) {
  const [mutate, { status, reset, data }]: [
    (roleValues: RoleFormValues) => any,
    MutationResult<EditRoleResponse>
  ] = useMutation(editRole(role.name), {
    onSuccess: () => {
      queryCache.refetchQueries("roles");
    },
  });
  useEffect(() => {
    if (status === "success" && !data.error) {
      onClose();
    }
  }, [onClose, status, data]);
  return (
    <RoleForm
      initialValues={{
        name: role.name,
        description: role.description || "",
      }}
      onSubmit={mutate}
      isSubmitting={status === "loading"}
      errorSubmitting={status === "error" || (!!data && !!data.error)}
      errorMessage={data?.error}
      clearSubmittingError={reset}
      onClose={onClose}
    />
  );
}
