import React from "react";
//@ts-ignore
import { useMutation, queryCache } from "react-query";
import { UserFormValues } from "./UserForm";
import UserFormDialogContent from "./UserFormDialogContent";
import firebase from "../firebase";

interface EditUserResponse {
  success?: string;
  error?: string;
}

interface Props {
  user: firebase.UserRecord;
  onClose: () => void;
}

const editUser = (userId: string) => async (userValues: UserFormValues) => {
  const token = (await firebase.auth().currentUser?.getIdToken()) as string;
  return fetch("/api/users", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-ID-Token": token,
    },
    body: JSON.stringify({
      id: userId,
      ...userValues,
    }),
  }).then((res) => res.json());
};

export default function EditUserForm({ user, onClose }: Props) {
  const [mutate, { status, data, reset }]: [
    (userValues: UserFormValues) => any,
    MutationResult<EditUserResponse>
  ] = useMutation(editUser(user.uid), {
    onSuccess: () => {
      queryCache.refetchQueries("users");
    },
  });
  return (
    <UserFormDialogContent
      user={user}
      saveStatus={status === "success" && data.error ? "error" : status}
      onSave={mutate}
      onErrorClose={reset}
      onClose={onClose}
    />
  );
}
