import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import firebase from "../firebase";

type LoadingStatus = "loading" | "finished";

const UserContext = createContext<[LoadingStatus, firebase.User | null]>([
  "loading",
  null,
]);
function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [status, setStatus] = useState<LoadingStatus>("loading");
  const contextValue = useMemo(() => [status, user], [status, user]) as [
    LoadingStatus,
    firebase.User | null
  ];
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((authUser) => {
      authUser ? setUser(authUser) : setUser(null);
      if (status === "loading") {
        setStatus("finished");
      }
    });
  }, [status, setUser, setStatus]);
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

function useCurrentUser() {
  return useContext(UserContext);
}

export { UserProvider, useCurrentUser };
