import React, { useEffect, Fragment } from "react";
import { makeStyles, CircularProgress, Snackbar } from "@material-ui/core";
//@ts-ignore
import { useQuery } from "react-query";
import { ServerDownMessage } from "./IllustrationMessage";
import Alert from "@material-ui/lab/Alert";
import UserForm, { UserFormValues } from "./UserForm";
import firebase from "../firebase";

const useStyles = makeStyles((theme) => ({
  spinner: {
    margin: "auto",
    display: "block",
    marginBottom: theme.spacing(2),
  },
}));

interface RoleResponse {
  roles: Role[];
}

interface Props {
  saveStatus: "loading" | "error" | "success";
  onSave: (values: UserFormValues) => any;
  onErrorClose: () => void;
  onClose: () => void;
  user?: firebase.UserRecord;
}

export default function UserFormDialogContent({
  onClose,
  saveStatus,
  onSave,
  onErrorClose,
  user,
}: Props) {
  const classes = useStyles();
  const { data, status }: QueryResult<RoleResponse> = useQuery(
    "roles",
    async () => {
      const token = (await firebase.auth().currentUser?.getIdToken()) as string;
      return fetch("/api/roles", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Firebase-ID-Token": token,
        },
      }).then((res) => res.json());
    }
  );
  useEffect(() => {
    if (saveStatus === "success") {
      onClose();
    }
  }, [onClose, saveStatus]);
  const handleSubmit = (values: UserFormValues): any => {
    const phoneNumber = values.phoneNumber && `+251${values.phoneNumber}`;
    onSave({
      ...values,
      phoneNumber,
    });
  };
  const initialValues: UserFormValues = user
    ? {
        fullName: user.displayName || "",
        email: user.email || "",
        phoneNumber: user.phoneNumber && user.phoneNumber.substr(4),
        roleNames: Object.keys(user.customClaims ?? []),
      }
    : {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        roleNames: [],
      };
  return (
    <Fragment>
      {status === "loading" && (
        <CircularProgress className={classes.spinner} color="secondary" />
      )}
      {status === "error" && (
        <ServerDownMessage message="Problem occurred connecting to server" />
      )}
      {status === "success" && data.error && (
        <Alert severity="error">{data.error}</Alert>
      )}
      {status === "success" && data.roles && (
        <UserForm
          initialValues={initialValues}
          roles={data.roles}
          onSubmit={handleSubmit}
          omitPassword={!!user}
          onClose={onClose}
        />
      )}
      <Snackbar
        open={saveStatus === "error"}
        autoHideDuration={3000}
        onClose={onErrorClose}>
        <Alert elevation={6} variant="filled" severity="error">
          Problem occurred saving user
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
